/* ---------- */
/* CHEVRON-UP */
/* ---------- */

$(function () {
    const page = window.location.pathname;

    $('body > footer').append('<a href="' + page + '#" class="chevronUp" title="Revenir au haut de page"><i class="far fa-arrow-alt-circle-up"></i></a>');

    $('.chevronUp').css({
        'position': 'fixed',
        'right': '2rem',
        'bottom': '5rem',
        'display': 'none',
        'background': '#f5f5f5',
        '-moz-border-radius': '50%',
        '-webkit-border-radius': '50%',
        'border-radius': '50%',
        'opacity': '0.9',
        'z-index': '2000',
    });
    $('.chevronUp').children('i').css({
        'fontSize': '2.5rem',
    });

    $('.chevronUp').children().css({
        'color': 'rgba(85, 85, 85, 1)',
    });

    // Au scroll.
    $(window).on('scroll', function () {
        posScroll = $(document).scrollTop();
        if (posScroll >= 300) {
            $('.chevronUp').fadeIn(400);
        } else {
            $('.chevronUp').fadeOut(400);
        }
    });

    // Au survol.
    $('.chevronUp').on('mouseover', function () {
        $(this).css({
            'color': '#ffa500',
        });
        $(this).children().css({
            'color': '#ffa500',
        });
    });

    // Quand la souris sort.
    $('.chevronUp').on('mouseleave', function () {
        $(this).children().css({
            'color': 'rgba(85, 85, 85, 1)',
        });
    });
});
